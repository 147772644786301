import React from 'react'

const HomePage = () => {


    return(
        <div>
            <h1>African Marketplace HomePage</h1>
        </div>
    )


}

export default HomePage